<template>
<div>

 <form-wizard
  color="#9600ff"
  :title="$t(null)"
  :subtitle="$t(null)"
  layout="vertical"
  :finish-button-text="$t('Submit')"
  :back-button-text="$t('Previous')"
  class="vertical-steps steps-transparent mb-3"
  ref="wizard"
>
  <!-- Meetings -->

  <!-- Meeting General Informations -->
  <tab-content :title="$t('Account')" icon="feather icon-user">
    <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
  </tab-content>

  <!-- Meeting invites -->
  <tab-content :title="$t('User_information')" icon="feather icon-info">
    <user-edit-tab-information :user-data="userData" class="mt-2 pt-75" />
  </tab-content>

  <!-- Meeting Advanced settings-->
  <tab-content :title="$t('Social_information')" icon="feather icon-share-2">
    <user-edit-tab-social :user-data="userData" class="mt-2 pt-75" />
  </tab-content>

  <tab-content :title="$t('Update_password')" icon="feather icon-settings">
    <user-edit-password class="mt-2 pt-75" />
  </tab-content>

</form-wizard>

    <!-- <b-tabs v-if="userData" pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <user-edit-tab-social :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Update password</span>
        </template>
        <user-edit-password class="mt-2 pt-75" />
      </b-tab>
    </b-tabs> -->

  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink,  BRow, BCol } from "bootstrap-vue";
import store from "@/store";

import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";

import userStoreModule from "./userStoreModule";
import UserEditTabAccount from "./user-profile/UserEditTabAccount.vue";
import UserEditTabInformation from "./user-profile/UserEditTabInformation.vue";
import UserEditTabSocial from "./user-profile/UserEditTabSocial.vue";
import UserEditPassword from "./user-profile/UserEditPassword.vue";


import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import axios from "axios";

export default {
  components: {
    FormWizard,
    TabContent,

    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabSocial,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditPassword,

    BRow,
    BCol
  },
   mounted(){
        this.$refs.wizard.maxStep = 3
        this.$refs.wizard.navigateToTab(0)   
   },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    axios
      .get(
        "https://backendapinodejs.timecheckit.com/api/users?id=" +
          JSON.parse(localStorage.getItem("userData"))._id
      )
      .then((response) => {
        console.log("user123",response);
        userData.value = {
          userName: response.data.data.name,
          email: response.data.data.email,
          avatar: require("@/assets/images/avatars/1.png"),
          dob: response.data.data.birthday,
          mobile: response.data.data.mobile,
          social: {
            twitter: response.data.data.social
              ? response.data.data.social.twitter
              : null,
            facebook: response.data.data.social
              ? response.data.data.social.facebook
              : null,
            instagram: response.data.data.social
              ? response.data.data.social.instagram
              : null,
          },
          adresse: {
            country: response.data.data.adresse
              ? response.data.data.adresse.country
              : null,
            city: response.data.data.adresse
              ? response.data.data.adresse.city
              : null,
          },
          description:response.data.data.description
        };
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // userData.value = {
    //   id: 21,
    //   userName: "Selina Kyle",
    //   firstName: "Test",
    //   lastName: "User",
    //   company: "Wayne Enterprises",
    //   role: "admin",
    //   username: "catwomen1940",
    //   country: "USA",
    //   contact: "(829) 537-0057",
    //   email: "irena.dubrovna@wayne.com",
    //   currentPlan: "team",
    //   status: "active",
    //   avatar: require("@/assets/images/avatars/1.png"),
    // };
    // store
    //   .dispatch("app-user/fetchUser", { id: 21 })
    //   .then((response) => {
    //     userData.value = response.data;
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined;
    //     }
    //   });

    return {
      userData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// [dir] .steps-transparent .wizard-tab-content {
//   background: none !important;
//   -webkit-box-shadow: none !important;
//   box-shadow: none !important;
// }
[dir="ltr"] .steps-transparent .wizard-card-footer {
  margin-top:20px ;
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
// [dir] .vue-form-wizard .wizard-tab-content {
//   padding: 10px 1px 15px !important;
// }
</style>
