<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">    {{ $t('Personal_Information_Heading') }}
</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
         <b-form-group :label="$t('Birth_Date')" label-for="birth-date">
  <flat-pickr
    v-model="userData.dob"
    class="form-control"
    :config="{ dateFormat: 'Y-m-d' }"
    :placeholder="$t('YYYY_MM_DD')"
  />
</b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col cols="12" md="6" lg="4">
        <b-form-group :label="$t('Mobile')" label-for="mobile">
  <b-form-input id="mobile" v-model="userData.mobile" />
</b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col cols="12" md="6" lg="4">
 <b-form-group :label="$t('Gender')" label-for="gender" label-class="mb-1">
  <b-form-radio-group
    id="gender"
    v-model="userData.gender"
    :options="genderOptions"
    value="male"
  />
</b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">{{ $t('Address') }}</h4>
      </div>

      <!-- Form: Personal Info Form -->

      <b-row class="mt-1">
        <!-- Field: Country -->
        <b-col cols="12" md="6" lg="6">
        <b-form-group :label="$t('Country')" label-for="country">
  <b-form-input
    id="country"
    v-model="userData.adresse.country"
    :placeholder="$t('United_States')"
  />
</b-form-group>
        </b-col>
        <!-- Field: City -->
        <b-col cols="12" md="6" lg="6">
<b-form-group :label="$t('City')" label-for="city">
  <b-form-input id="city" v-model="userData.adresse.city" />
</b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="informationData()"
          >
              {{ $t('Save_changes') }}
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
             {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const userDataInfo = ref({
    //   dob: null,
    //   mobile: "+6595895857",
    //   website: "https://rowboat.com/insititious/Angelo",
    //   language: "French",
    //   gender: "female",
    //   contactOptions: ["Email", "Message"],
    //   addressLine1: "A-65, Belvedere Streets",
    //   addressLine2: "",
    //   postcode: "",
    //   city: "New York",
    //   state: "",
    //   country: "",
    // });

    const genderOptions = [
      { text: "Male", value: "male" },
      { text: "Female", value: "female" },
    ];

    // const contactOptionsOptions = ["Email", "Message", "Phone"];

    return {
      // userDataInfo,
      genderOptions,
      // contactOptionsOptions,
    };
  },
  created() {
    console.log("this.userData", this.userData);
    console.log("this", this);
  },
  methods: {
    informationData() {
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/users/" +
            JSON.parse(localStorage.getItem("userData"))._id,
          {
            birthday: this.userData.dob,
            mobile: this.userData.mobile,
            gender: this.userData.gender,
            adresse: {
              country: this.userData.adresse.country,
              city: this.userData.adresse.city,
            },
          }
        )
        .then((response) => {
          console.log("profile2", response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Profile updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
