<template>
  <div>
       <div class="d-flex">
      <feather-icon icon="SettingsIcon" size="19" />
      <h4 class="mb-0 ml-50">{{ $t('Password_update') }}</h4>
    </div>
<br>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="12">
          <b-form-group :label="$t('Old_Password_Label')" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
        :placeholder="$t('Old_Password_Placeholder')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
 <b-col md="6">
    <b-form-group
      :label="$t('New_Password_Label')"
      label-for="account-new-password"
    >
      <b-input-group class="input-group-merge">
        <b-form-input
          id="account-new-password"
          v-model="newPasswordValue"
          :type="passwordFieldTypeNew"
          name="new-password"
          :placeholder="$t('New_Password_Placeholder')"
        />
        <b-input-group-append is-text>
          <feather-icon
            :icon="passwordToggleIconNew"
            class="cursor-pointer"
            @click="togglePasswordNew"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-col>

  <!-- retype password -->
  <b-col md="6">
    <b-form-group
      :label="$t('Retype_New_Password_Label')"
      label-for="account-retype-new-password"
    >
      <b-input-group class="input-group-merge">
        <b-form-input
          id="account-retype-new-password"
          v-model="RetypePassword"
          :type="passwordFieldTypeRetype"
          name="retype-password"
          :placeholder="$t('New_Password_Placeholder')" 
        
        />
        <b-input-group-append is-text>
          <feather-icon
            :icon="passwordToggleIconRetype"
            class="cursor-pointer"
            @click="togglePasswordRetype"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-col>
        <!--/ retype password -->

        <!-- buttons -->
      </b-row>
        <b-row>
          <b-col class="mt-2 d-flex justify-content-end">
            <b-button
              @click="informationData()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              {{ $t('Save_changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
             {{ $t('Reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    informationData() {
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/users/" +
            JSON.parse(localStorage.getItem("userData"))._id +
            "/new-password",
          {
            currentPassword: this.passwordValueOld,
            newPassword: this.newPasswordValue,
          }
        )
        .then((response) => {
          console.log("profile2", response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Profile updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
};
</script>
