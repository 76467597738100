<template>
<div>
   <div class="d-flex">
      <feather-icon icon="Share2Icon" size="19" />
<h4 class="mb-0 ml-50">{{ $t('Social_Media') }}</h4>
    </div>
<br>
 <b-form>
    <b-row>
      <b-col
        v-for="socialField in socialInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon size="16" :icon="socialField.icon" />
            </b-input-group-prepend>
            <b-form-input
              :id="socialField.dataField"
              v-model="userData.social[socialField.dataField]"
              type="url"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="mt-2 d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="socialData()"
        >
              {{ $t('Save_changes') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
             {{ $t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</div>
 
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  setup() {
    // const userDataSocial = {
    //   twitter: "https://www.twitter.com/adoptionism744",
    //   facebook: "https://www.facebook.com/adoptionism664",
    //   instagram: "https://www.instagram.com/adopt-ionism744",
    // };

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: "TwitterIcon",
        dataField: "twitter",
        label: "Twitter",
      },
      {
        icon: "FacebookIcon",
        dataField: "facebook",
        label: "facebook",
      },
      {
        icon: "InstagramIcon",
        dataField: "instagram",
        label: "instagram",
      },
    ];

    return {
      // userDataSocial,
      socialInputs,
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    socialData() {
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/users/" +
            JSON.parse(localStorage.getItem("userData"))._id,
          {
            social: {
              twitter: this.userData.social.twitter,
              facebook: this.userData.social.facebook,
              instagram: this.userData.social.instagram,
            },
          }
        )
        .then((response) => {
          console.log("profile2", response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Profile updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style>
</style>
